<template>
    <div>
        <inquiries-table :status="'SOLVED'"></inquiries-table>
    </div>
</template>
<script>
import InquiriesTable from './InquiriesTable.vue'
export default {
    components: {
        "inquiries-table": InquiriesTable
    }
}
</script>